import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApplicationListResponse } from './bravia';

@Injectable({
  providedIn: 'root',
})
export class BraviaApiService {
  ip = 'localhost';
  constructor(private http: HttpClient) {}

  async launchAppli(uri: string) {
    const psk = '123456';
    const ngHeader = new HttpHeaders({
      'X-Auth-PSK': psk,
      'Content-Type': 'application/json',
    });
    const json = JSON.stringify({
      method: 'setActiveApp',
      version: '1.0',
      id: 1,
      params: [
        {
          uri: uri,
          data: '',
        },
      ],
    });
    return this.http
      .post('http://' + this.ip + '/sony/' + 'appControl', json, {
        headers: ngHeader,
      })
      .toPromise();
  }

  sendHomeKey() {
    // IRCC-IP

    const code = 'AAAAAQAAAAEAAABgAw==';

    const ip = 'localhost';
    const psk = '123456';
    const ngHeader = new HttpHeaders({
      'X-Auth-PSK': psk,
      'Content-Type': 'application/json',
    });

    const xhr = new XMLHttpRequest();
    xhr.open('POST', 'http://' + ip + '/sony/IRCC');
    xhr.setRequestHeader(
      'SOAPACTION',
      '"urn:schemas-sony-com:service:IRCC:1#X_SendIRCC"'
    );
    xhr.setRequestHeader('Content-Type', 'text/xml; charset=UTF-8');
    if (psk) {
      xhr.setRequestHeader('X-Auth-PSK', psk);
    }

    const body =
      '<s:Envelope xmlns:s="http://schemas.xmlsoap.org/soap/envelope/" s:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
      '<s:Body>' +
      '<u:X_SendIRCC xmlns:u="urn:schemas-sony-com:service:IRCC:1">' +
      '<IRCCCode>' +
      code +
      '</IRCCCode>' +
      '</u:X_SendIRCC>' +
      '</s:Body>' +
      '</s:Envelope>';
    xhr.send(body);
  }

  getAppList() {
    const json = JSON.stringify({
      method: 'getApplicationList',
      id: 3,
      params: [],
      version: '1.0',
    });
    const psk = '123456';
    const ngHeader = new HttpHeaders({
      'X-Auth-PSK': psk,
      'Content-Type': 'application/json',
    });
    return this.http.post<ApplicationListResponse>(
      'http://' + this.ip + '/sony/' + 'appControl',
      json,
      {
        headers: ngHeader,
      }
    );
  }
}

import {
  Component,
  OnInit,
  OnDestroy,
  AfterViewInit,
  VERSION,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Iplist } from './iplist';
import { Newslist } from './news';
import { Subscription, forkJoin, combineLatest } from 'rxjs';
import { nowDate } from './nowDate';
import { BraviaApiService } from '../service/bravia-api.service';
import { RoomNoService } from '../service/room-no.service';
import {
  getNowDateObject,
  getStreamNowDate,
} from '../shared/tools/DateNowTime';
import { LanguageService } from '../service/language.service';
import { WeatherService } from '../service/weather/weather.service';
declare function require(x: string): any;

declare var Skycons: any;
@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent implements OnInit, OnDestroy, AfterViewInit {
  isEng: boolean;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private braviaService: BraviaApiService,
    private roomNoService: RoomNoService,
    private languageService: LanguageService,
    private weatherService: WeatherService
  ) {}
  lang = '';
  ip = '';
  ssid = '';
  capacity: number;
  qr = '';
  password = '';
  room_no = '';
  sub: Subscription[] = [];
  now_date = getStreamNowDate();
  news: Newslist;
  icons: Icon[] = [
    {
      uri: 'stay-info',
      icon: 'assets/icons/com/service.svg',
      link: 'hoge',
      title_ja: 'サービス',
      title_eng: 'Service Information',
      focused: true,
    },
    {
      uri: 'hotel-info',
      icon: 'assets/icons/com/hotel.svg',
      link: 'mirror',
      title_ja: '館内施設',
      title_eng: 'Information',
      focused: false,
    },
    {
      uri: 'sendHomeKey',
      icon: 'assets/icons/com/tv.svg',
      link: 'tv',
      title_ja: '地デジ',
      title_eng: 'TV',
      focused: false,
    },
    {
      uri: 'app-list',
      icon: 'assets/icons/com/app.svg',
      link: 'app',
      title_ja: '動画アプリ',
      title_eng: 'Streaming Apps',
      focused: false,
    },
    /*    {
			  uri: 'restaurant',
			  icon: 'assets/icons/com/restaurant.svg',
			  link: 'restaurant',
			  title_ja: 'レストラン',
			  title_eng: 'Restaurant',
			  focused: false
			  },
		*/
    {
      uri: 'nearby-info',
      icon: 'assets/icons/com/map.svg',
      link: 'nearby-info',
      title_ja: '周辺情報',
      title_eng: 'Tourist Information',
      focused: false,
    },

    {
      uri: 'yakkan',
      icon: 'assets/icons/com/book.svg',
      link: 'yakkan',
      title_ja: '宿泊約款',
      title_eng: 'Provision',
      focused: false,
    },
    {
      uri: 'mirror-guide',
      icon: 'assets/icons/com/mirroring.svg',
      link: 'mirroring',
      title_ja: 'ミラーリング',
      title_eng: 'Mirroring',
      focused: false,
    },
    {
      uri: 'changeLang',
      icon: 'assets/icons/com/translation.svg',
      link: 'wifi',
      title_ja: 'English',
      title_eng: '日本語',
      focused: false,
    },
  ];

  now_date_obj: nowDate = getNowDateObject(
    new Date(),
    this.languageService.language
  );
  today_rainy: string; // 今日の降水確率
  tommorow_rainy: string; // 明日の降水確率
  today_temp: string;
  tommorow_temp: string;

  ngOnInit() {
    if (this.languageService.language === 'eng') {
      this.isEng = true;
    }

    /*    particlesJS.load('particles-js', '/assets/particles.json', null);*/
    //this.changeIconForLang(this.languageService.language);

    this.weatherService.getWeather().subscribe(d => {
      this.today_rainy = Math.round(d.daily[0].pop * 10) * 10 + '%';
      this.tommorow_rainy = Math.round(d.daily[1].pop * 10) * 10 + '%';
      this.today_temp =
        Math.round(d.daily[0].temp.max) +
        '℃ / ' +
        Math.round(d.daily[0].temp.min) +
        '℃';
      this.tommorow_temp =
        Math.round(d.daily[1].temp.max) +
        '℃ / ' +
        Math.round(d.daily[1].temp.min) +
        '℃';
      const skycons = new Skycons({ color: '#1c3162' });

      skycons.add('today', Skycons[this.getSkycon(d.daily[0].weather[0].icon)]);
      skycons.add(
        'tomorrow',
        Skycons[this.getSkycon(d.daily[1].weather[0].icon)]
      );

      skycons.play();
    });
    // 現在時刻の表示
    this.sub.push(
      this.now_date.subscribe(d => {
        this.now_date_obj = getNowDateObject(d, this.languageService.language);
      })
    );
    // this.sub.push(
    combineLatest(this.route.paramMap, this.route.queryParams).subscribe(d => {
      const room_no = d[0].get('room');
      const ssid = d[1]['ssid'];
      if (!room_no) {
        // alert('部屋番号が設定されていません');
        // TODO エラーページに飛ばせばええんちゃう
      }
      const ip_list: Iplist[] = require('./iplist.json');
      const ip = ip_list.find(list => list.room_no === room_no);
      if (!ip) {
        // alert('存在しない部屋番号です');
        // TODO エラーページに飛ばせばええんちゃう
      }
      this.room_no = room_no; // 部屋番号
      this.ssid = ssid; // ssid

      //NEWS.json よみ込みする
      const news_list: Newslist[] = require('./news.json');
      this.news = news_list[0];

      this.capacity = 0;
      this.roomNoService.room_no = room_no; // 部屋番号をserviceに保存
      this.password = d[1]['key'];
      if (this.password) {
        localStorage.setItem('password', this.password);
        localStorage.setItem('ssid', this.ssid);
      } else {
        this.password = localStorage.getItem('password');
        this.ssid = localStorage.getItem('ssid');
      }
      this.qr = `WIFI:S:${this.ssid};T:WPA;P:${this.password};;`;
    });
    //this.sub.push(
    // 指定言語によるアイコンの切り替え
    //      this.languageService.getObservable().subscribe(l => {
    //        this.changeIconForLang(l);
    //      })
    //    );
  }

  //changeIconForLang(l: string) {
  //  this.icons = this.icons.map(d => {
  //    const split_path = d.icon.split('/');
  //    split_path[2] = l;
  //    d.icon = split_path.join('/');
  //    return d;
  //  });
  //}

  ngAfterViewInit() {
    (<any>document.querySelector('.icon_item')).focus();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.forEach(d => d.unsubscribe());
    }
  }

  async aClick(i: number) {
    // なぜか他画面から戻った時にフォーカスが当たっている位置のクリックイベントが発火してしまう
    if (this.roomNoService.source !== '') {
      this.roomNoService.source = '';
      return;
    }
    if (/^com./.test(this.icons[i].uri)) {
      await this.braviaService.launchAppli(this.icons[i].uri);
    } else if (/^http./.test(this.icons[i].uri)) {
      window.open(this.icons[i].uri);
    } else if (/^sendHomeKey/.test(this.icons[i].uri)) {
      this.braviaService.sendHomeKey();
    } else if (/^changeLang/.test(this.icons[i].uri)) {
      this.changeLang();
    } else {
      this.router.navigate([this.icons[i].uri]);
    }
  }

  focus(i: number) {
    this.icons.forEach(d => (d.focused = false));
    this.icons[i].focused = true;
  }

  changeIcon(i: number) {
    this.icons[i].focused = true;
  }
  blur() {
    this.icons.forEach(d => (d.focused = false));
  }
  changeLang() {
    if (this.languageService.language === 'jpn') {
      this.isEng = true;
      this.languageService.setEn();
    } else if (this.languageService.language === 'eng') {
      this.isEng = false;
      this.languageService.setJa();
    }
  }
  getSkycon(name: String): any {
    switch (name.slice(0, 2)) {
      case '01':
        return 'CLEAR_DAY';
      case '02':
        return 'PARTLY_CLOUDY_DAY';
      case '03':
        return 'CLOUDY';
      case '04':
        return 'CLOUDY';
      case '09':
        return 'RAIN';
      case '10':
        return 'RAIN';
      case '11':
        return 'RAIN';
      case '13':
        return 'SNOW';
      case '50':
        return 'FOG';
      default:
        return 'CLEAR_DAY';
    }
  }
}
